import React from "react"
import postMessage from "../services/react-native/postMessage"

function UserAgreementLinks({
  message = "By clicking on login you agree to the",
}) {
  return (
    <div>
      <p>
        {message}{" "}
        <a 
          onClick={() => {
            postMessage('VIEWING_PRIVACY_POLICY')
          }}
          href={
            "https://firebasestorage.googleapis.com/v0/b/com-sparkle-sparkle.appspot.com/o/SparkleStar%20Privacy%20Policy.pdf?alt=media&token=9335acfb-ab92-4fec-984c-3dafaca5c8e1"
          }
          target="_blank"
        >
          Privacy Policy
        </a>{" "}
        and{" "}
        <a
          onClick={() => {
            postMessage('VIEWING_TERMS_CONDITIONS')
          }}
          href={
            "https://firebasestorage.googleapis.com/v0/b/com-sparkle-sparkle.appspot.com/o/SparkleStar%20Terms%20of%20Use.pdf?alt=media&token=5ce66bb5-a4d9-4e7b-99cb-7413deeb81b5"
          }
          target="_blank"
        >
          Terms of use
        </a>
      </p>
    </div>
  )
}

export default UserAgreementLinks
